import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/card"
import Newsletter from "../components/newsletter"
import IconBookLeaf from "../../static/icons/book-leaf-open.svg";

const NewsPage = () => (
  <Layout>
    <Seo title="Technology" description="ECO-Qube’s smart cooling system, which benefits from artificial intelligence (AI), will effectively use Computational Fluid Dynamics (CFD) simulations to adapt cooling system and IT devices." />
    <div className="max-w-screen-lg mx-auto px-4 relative z-20 mt-20">
      <div className="max-w-screen-md mx-auto text-center">
        <div className="inline-flex justify-center items-center">
          <div className="relative inline-block mr-6">
            <img src={'../cube-skelleton.svg'} className="w-16" />
            <IconBookLeaf className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Technology</h2>
        </div>
        <p className="mt-4 text-center text-2xl">ECO-Qube’s smart cooling system, which benefits from artificial intelligence (AI), will effectively use Computational Fluid Dynamics (CFD) simulations to adapt cooling system and IT devices.</p>
      </div>
      <div className="grid grid-cols-2 gap-8 lg:gap-12 mt-12 items-start">
        <div className="grid gap-12 items-start">
          <Card
            className="bg-layout-ocean-base"
            tagline="Preventive Strategies"
            title="Component Observation"
            text="ECO-Qube benefit from big data created by the observation of the active components like computing equipment to develop a zonal heat management system. This process includes monitoring CPU utilization, temperature and power consumption of the IT devices to operate the zonal heat management system, which predicts zonal temperature rises in advance."
          >
            <img src={'../cube-observation.png'} className="w-20" />
          </Card>
          <Card
            className="bg-danger-base"
            tagline="Supportive Strategies"
            title="Waste Heat Valorisation"
            text="ECO-Qube energy management system (EMS) will manage energy trade between the buildings’ energy management system and the data centre. ECO-Qube accepts the fact that data centres are dynamic systems where the energy demand changes rapidly depending on IT load, power density or external ambient conditions such as seasonal variation and weather conditions. ECO-Qube aims to use minimum energy from energy lines while providing maximum amount of energy from renewable energy sources. To achieve this, ECO-Qube should be integrated building/district’s energy management systems to benefit from renewable energy sources and waste heat valorization opportunities. This integration requires management of the energy demand and supply balance between the building and data centre where ECO-Qube EMS is authorized to directly actuate."
          >
            <img src={'../cube-waste-heat.png'} className="w-20" />
          </Card>
          <Card
            className="bg-danger-base"
            tagline="Supportive Strategies"
            title="Renewable Energy Application"
            text="Renewable energy is becoming more available and data centre power consumption can be matched with weather-dependent energy production. Integrated into urban areas, digital infrastructure can supply much needed emission-free heating. Server Hardware can be recycled to reduce waste. Efficiency in existing data centres can be increased by utilizing idling servers better. The digital infrastructure of the future will be emission-free, affordable and accessible for everyone developing next-generation digital technology."
          >
            <img src={'../cube-renewable-energy.png'} className="w-20" />
          </Card>
          <Card
            className="bg-layout-ocean-base"
            tagline="Preventive Strategies"
            title="Zonal Heat Management"
            text="With the help of zonal heat management, smart workload orchestration will be able to transfer the workload from hot areas to colder areas to keep the system thermally balanced for even better energy efficiency. Furthermore, to benefit from different outside variables (like seasonal characteristics, outside temperature and time differences), smart workload orchestration of ECO-Qube will be able to transfer the workload between different data centres from different buildings, cities, countries and continents to benefit from the best conditions for the minimum energy consumption."
          >
            <img src={'../cube-zonal-heat.png'} className="w-20" />
          </Card>
        </div>
        <div className="grid gap-12 items-start">
        <Card
            className="bg-layout-ocean-base"
            tagline="Preventive Strategies"
            title="CFD Analysis"
            text="ECO-Qube will start a new era for data centre management systems with real-time adaptation of the data centres to the dynamic cooling and energy requirements of the data centres. The smart cooling system will be supported by artificial intelligence, where the system will make its own decisions, which will reduce the dependency of a human controller. Moreover, the system will have machine learning capabilities, to learn from insufficient performance, embedded with a risk analysis and prevention mechanism."
          >
            <img src={'../cube-cfd.png'} className="w-20" />
          </Card>
          <Card
            className="bg-danger-base"
            tagline="Supportive Strategies"
            title="Smart Power Management"
            text="Endoks has been developing smart energy management systems with multiple references which makes this technology TRL 9. However, within ECO-Qube, new communication capabilities to EMS which will cooperate with building’s EMS and conduct the data centres’ demand and management options need to be developed and added to the existing Endoks product. Endoks EMS will be installed to the pilot zones and the system will be improved with additional metrics that ECO-Qube will be working on during the project like PUE and CO2 savings. Then the system will be provided by the algorithms for decision making to realize energy supply depending on the energy demand."
          >
            <img src={'../cube-smart.png'} className="w-20" />
          </Card>
          <Card
            className="bg-danger-base"
            tagline="Supportive Strategies"
            title="Alternative Energy Strategies"
            text="ECO-Qube approach includes preventive strategies to reduce consumption of the computing equipment & cooling systems and supportive strategies to achieve a high share of the ICT energy consumption covered by sustainable energy sources."
          >
            <img src={'../cube-alternative.png'} className="w-20" />
          </Card>
          <Card
            className="bg-layout-ocean-base"
            tagline="Preventive Strategies"
            title="Smart Scheduling"
            text="Helio builds and provides a technology platform & software that allows for intelligent workload scheduling to deploy any workload to almost anywhere in the world."
          >
            <img src={'../cube-scheduling.png'} className="w-20" />
          </Card>
          <Card
            className="bg-layout-ocean-base"
            tagline="Preventive Strategies"
            title="Smart Cooling"
            text="ECO-Qube will introduce innovative dynamic cooling supported by zonal heat management into a unique system fully interconnected with the building. The system will exploit the big data formed by the monitoring of the CPU utilization, temperature and power consumption of the IT devices to operate the zonal heat management system, which predicts zonal temperature rises. ECO-Qube instantaneously reads the cooling requirements of the data centre, making the data available for the AI supported cooling system and control over the cooling & energy management systems and IT devices in order to obtain the maximum energy efficiency of the whole integrated facility."
          >
            <img src={'../cube-cooling.png'} className="w-20" />
          </Card>
        </div>
      </div>
    </div>
    <div className="max-w-screen-sm mx-auto px-4 mt-20 lg:mt-40 relative">
      <Newsletter />
    </div>
    
  </Layout>
)

export default NewsPage
